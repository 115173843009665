import React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';

import './products.css'

function PageProducts() {
  const data = useStaticQuery(graphql`
  query {
    boxInputs:file(relativePath: {eq: "clear-glass-mug-with-cappuccino-on-white-ceramic-saucer.jpg"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    boxCoffee: file(relativePath: {eq: "close-up-of-coffee-beans-in-bowl.jpg"}) {
      childImageSharp {
        fixed(width: 300, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    boxEquipament: file(relativePath: {eq: "black-and-gray-coffeemaker.jpg"}) {
      childImageSharp {
        fixed(width: 300, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)
  return (
      <section className="products">
          <div className="container-products">
              <Link to={"/product/inputs"} >
                <Img
                  id="coffee"
                  fixed={data.boxInputs.childImageSharp.fixed}
                  className="products-box inputs"
                  alt="Insumos"
                />
              </Link>
              <Link to={"/product/coffee"}>
                <Img
                  fixed={data.boxCoffee.childImageSharp.fixed}
                  className='products-box coffee'
                  alt="Cafés"/>
              </Link>
              <Link to={"/product/equipament"}>
                <Img
                  fixed={data.boxEquipament.childImageSharp.fixed}
                  className='products-box equipament'
                  alt="Equipamentos"
                 />
              </Link>
          </div>
      </section>
  );
}

export default PageProducts;