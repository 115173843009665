import React from 'react';

import PageProducts from '../components/Products/products';
import Layout from '../components/Layout/layout';
import SEO from '../components/seo';

function Products() {
  return (
    <Layout>
      <SEO title="Product" />
      <PageProducts/>
    </Layout>
  )
}

export default Products;